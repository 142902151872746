import { initializeApp, type FirebaseOptions } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

export const firebaseConfig = JSON.parse(
  import.meta.env.VITE_FIREBASE_CONFIG as string,
) as FirebaseOptions;

export const fb = initializeApp(firebaseConfig);

// // Initialize Firebase
export const db = getFirestore(fb);

// export const fbperf = getPerformance(fb)
export const fb_storage = getStorage(fb);
export const fb_auth = getAuth(fb);
export const on_auth_state_changed = onAuthStateChanged;
